<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        treeTitle="组织架构"
        :isShowdig="false"
        :showCheckbox="false"
        :showTopTip="false"
        :expandOnClickNode="false"
        :treeExpand="false"
        :nodeKey="'id'"
        :defaultExpandedKeys="defaultExpandedKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :loading="treeLoading"
        @getNodeClick="treeNodeClick"
      />
      <el-main style="position: relative;height: calc(100vh - 94px)">
        <head-layout
          class="head-section"
          :head-btn-options="headBtnOptions"
          head-title="境内项目人员信息"
          @head-save="headSave"
          @head-update="headUpdate"
          @head-submit="headSubmit"
          @head-summary="headSummary"
          @head-reportView="headReportView"
        ></head-layout>
        <div class="head-picker">
          <el-date-picker
            v-model="query.formDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            class="searchInput"
            style="width: 200px"
            size="mini"
            :disabled="this.type === 'workflow'"
            @change="selectList"
          >
          </el-date-picker>
        </div>
        <div>
          <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="240px" class="demo-ruleForm">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="组织名称" prop="orgName">
                  <el-input
                    v-model="dataForm.orgName"
                    controls-position="right"
                    :disabled="true"

                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="填报日期" prop="updateTime">
                  <template slot="label">
                    <span>填报日期:</span>
                  </template>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="dataForm.updateTime"
                    type="date"
                    disabled="true"
                    placeholder="保存后自动生成"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数据状态" prop="dataState">
                  <el-select v-model="dataForm.dataState" disabled placeholder="请选择">
                    <el-option
                      v-for="item in dataStateDic"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="custom-row" :gutter="20">
              <el-col :span="8">
                <el-form-item label="公司在册职工" prop="regeditedChineseCnt">
                  <el-input
                    v-model="dataForm.regeditedChineseCnt"
                    controls-position="right"

                    maxlength="8"
                    show-word-limit
                    :disabled="readonly"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="regeditedChineseCntS==null?'上次填报数据：0':'上次填报数据：' + regeditedChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="分包商（含劳务）人数数" prop="subcontractorChineseCnt">
                  <el-input
                    v-model="dataForm.subcontractorChineseCnt"
                    controls-position="right"

                    maxlength="8"
                    show-word-limit
                    :disabled="readonly"
                  ></el-input>
                  <el-tooltip class="item" effect="light"
                              :content="regeditedChineseCntS==null?'上次填报数据：0':'上次填报数据：' + subcontractorChineseCntS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="项目总人数" style="align-items: center;">
                  <div style="margin-left: 0 !important;">{{ totalChineseCnt }}</div>
                  <el-tooltip class="item" effect="light"
                              :content="regeditedChineseCntS==null?'上次填报数据：0':'上次填报数据：' + totalCountS"
                              placement="bottom">
                    <i class="el-icon-warning-outline iconLine"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <head-layout
          head-title="下级组织项目人员信息"
          :head-btn-options="subordinateBtn"
          @head-reject="reject"
          @head-urge-processing="headUrging"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          class="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          class="domesticProjectSummary"
          ref="gridLayTableTopic"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="tableOnLoad"
          @page-size-change="tableOnLoad"
          @page-refresh-change="tableOnLoad"
          @grid-row-detail-click="rowView"
          :cell-style="cellStyle"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{row}">
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--              @click="rowEdit(row)"-->
            <!--            > 编辑-->
            <!--            </el-button>-->
            <!--            <el-button-->
            <!--              type="text"-->
            <!--              v-if="row.dataState === '4' && (dataForm.dataState === '1' || dataForm.dataState === '3')"-->
            <!--              @click="reject(row)"-->
            <!--            > 驳回-->
            <!--            </el-button>-->
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      :title="this.type == 'edit' ? '境内项目人员信息编辑' : (this.type == 'view' ? '境内项目人员信息查看' : '境内项目人员信息新增')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="isFormDialog"
      width="70%"
      top="5vh"
      @close="domesticDialog"
    >
      <editDialog ref="overseasProjectFillingEdit" :formData="formData" v-if="isFormDialog"
                  @domesticDialog="domesticDialog"></editDialog>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import CommonTree from "@/views/components/com_tree";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {
  getDataStatus,
  getDomesticDetail,
  reportTree,
  submitSummaryByDomestic,
  submitVerify,
  summaryClick, summaryClickDataState,
  summaryGetData,
  summaryGetPage, rejectSendingMessage
} from "@/api/foreignReport/domesticReport";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {detail as getProcessDetail, detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import Template from "@/views/message/template/list.vue";
import {dictionaryBiz} from "@/api/reportTasks";
import {formatTime} from "@/util";
import editDialog
  from "@/views/business/reportManager/summaryReport/domesticProjectSummary/editDialog.vue"
import website from "@/config/website";
import {reviewAndUrging} from "@/api/reportReview";

export default {
  name: "dataSummary",
  components: {
    editDialog,
    Template,
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonTree,
  },
  mixins: [exForm, draft],
  data() {
    return {
      treeLoading: false,
      orgCode: '',
      whether: true,
      fillDeptName: '',
      defaultExpandedKeys: [],
      treeShow: false,
      nodeId: '',
      isTreeVisible: true,
      isFormDialog: false,
      formData: {},
      processTemplateKey: "report_domestic_summary",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      treeData: [],
      defaultProps: {
        label: "deptName",
        value: "deptName",
        key: "id",
        children: 'children'
      },
      deptId: '',
      dataStateDic: [],
      summary: false,
      regeditedChineseCntS: '',
      subcontractorChineseCntS: '',
      totalCountS: '',
      dataState: '',
      dicData: [],
      readonly: true,
      defaultCheckedKeys: [],
      searchTitle: "title",
      paperPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      topicData: [],
      tableTopicData: [],
      selectTopicCodes: [],
      dataForm: {
        projectArea: "1",
        chineseCntS: "",//中方员工总数
        foreignerCntS: "",//外籍人员总人数
        totalCountS: "", //总人数
        otherForeignerCntS: "", //其他外籍人员数
        regularForeignerCntS: "", //在册外籍职工数
        subcontractorChineseCntS: "", //在册外籍职工数
        thirdPartyChineseCntS: "", //第三方中方技术服务人员数
        dispatchedChineseCntS: "", //劳务公司派遣中方员工数
        regeditedChineseCntS: "", //在册中方职工数
        chineseCnt: "",//中方员工总数
        foreignerCnt: "",//外籍人员总人数
        totalCount: "", //总人数
        otherForeignerCnt: "", //其他外籍人员数
        regularForeignerCnt: "", //在册外籍职工数
        subcontractorChineseCnt: "", //在册外籍职工数
        thirdPartyChineseCnt: "", //第三方中方技术服务人员数
        dispatchedChineseCnt: "", //劳务公司派遣中方员工数
        regeditedChineseCnt: "", //在册中方职工数
        statDate: "",//统计日期
        dataState: "",
        orgCode: "",
        dateSource: "2"
      },
      rules: {
        orgName: [
          {required: true, message: '请输入组织名称', trigger: ['blur']},
        ],
        regeditedChineseCnt: [
          {required: true, message: '请输入公司在册职工', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        subcontractorChineseCnt: [
          {required: true, message: '请输入分包商（含劳务）人数数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
      tableData: [],
      saveLoading: false,
      tableLoading: false,
      query: {
        formDate: "",
      },
      type: undefined,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        formDate: ''
      },
    }
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            document.querySelector(".el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    refresh() {
      return this.$store.state.common.report
    },
    headBtnOptions() {
      let result = [];
      if (this.whether) {
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          result.push({
            label: '汇总',
            emit: "head-summary",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          if (!this.readonly) {
            result.push({
              label: '保存',
              emit: "head-save",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          } else {
            result.push({
              label: '修改',
              emit: "head-update",
              type: "primary",
              icon: "el-icon-check",
              loading: this.saveLoading,
            })
          }
        }
        if (this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined || this.dataForm.dataState == "3") {
          result.push({
            label: '提交',
            emit: "head-submit",
            type: "primary",
            icon: "el-icon-check",
            loading: this.saveLoading,
          })
        }
        // if (!((this.dataForm.dataState == "1" || this.dataForm.dataState == "" || this.dataForm.dataState == undefined)) && this.dataForm.dataState != "3" && this.type != 'workflow') {
        //   result.push(
        //     {
        //       label: "流程详情",
        //       emit: "head-process_detail",
        //       type: "button",
        //       icon: "",
        //       btnOptType: "loseEfficacy",
        //     });
        // }

        // if (this.dataForm.dataState == "3" && this.type != 'workflow') {
        //   result.push(
        //     {
        //       label: "重新汇总",
        //       emit: "head-process_detail",
        //       type: "button",
        //       icon: "",
        //       btnOptType: "loseEfficacy",
        //     });
        // }
      }
      if (this.type != 'workflow') {
        result.push(
          {
            label: "报表查看",
            emit: "head-reportView",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }
      return result
    },
    subordinateBtn() {
      let result = [];
      if (this.dataForm.dataState != "4") {
        result.push(
          {
            label: "退回重新填报",
            emit: "head-reject",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });

        result.push(
          {
            label: "催办",
            emit: "head-urge-processing",
            type: "button",
            icon: "",
            btnOptType: "loseEfficacy",
          });
      }
      return result;
    },
    tableOptions() {
      return {
        selection: true,
        selectable: (row, index) => {
          return row.id != -1;
        },
        menu: false,
        linklabel: "projectName",
        column: [
          {
            label: "组织/项目名称",
            prop: "projectName",
            overHidden: true,
            fixed: 'left',
            align: 'center',
          },
          {
            label: "填报状态",
            prop: "dataState",
            type: "number",
            overHidden: true,
            align: 'center',
            fixed: 'left',
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=data_status_report',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          // {
          //   label: "组织/项目编号",
          //   prop: "projectCode",
          //   overHidden: true,
          //   align: 'center',
          // },
          {
            label: "统计日期",
            prop: "statDate",
            align: "center",
            menuFixed: 'right',
            width: 120,
            overHidden: true,
          },
          {
            label: "公司员工数",
            prop: "regeditedChineseCnt",
            overHidden: true,
            align: 'center',
          },
          {
            label: "分包商员工数",
            prop: "subcontractorChineseCnt",
            width: 120,
            overHidden: true,
            align: 'center',
          },
          {
            label: "总人数",
            prop: "totalCount",
            width: 100,
            overHidden: true,
            align: 'center',
          },
          {
            label: "统计日期",
            prop: "statDate",
            width: 110,
            overHidden: true,
            align: 'center',
          },
          {
            label: "数据来源",
            prop: "dateSource",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_data_source",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          {
            label: "创建日期",
            prop: "createTime",
            width: 170,
            overHidden: true,
            align: 'center',
          },
          {
            label: "创建人",
            prop: "createUserName",
            width: 70,
            overHidden: true,
            align: 'center',
          },
          {
            label: "更新日期",
            prop: "updateTime",
            width: 170,
            overHidden: true,
            align: 'center',
          },
          {
            label: "更新人",
            prop: "updateUserName",
            width: 70,
            overHidden: true,
            align: 'center',
          },
        ],
      }
    },
    searchColumns() {
      return [
        {
          label: "项目编号,项目名称",
          prop: "projectCode",
          span: 4,
          placeholder: "请输入项目编号,项目名称",
        },
        {
          label: "数据状态",
          prop: "dataState",
          type: "select",
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=data_status_report',
          dicData: [],
          placeholder: "请选择数据状态",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          span: 4,
        },
      ]
    },
    totalChineseCnt() {
      let number = 0;
      if (this.dataForm.regeditedChineseCnt || this.dataForm.subcontractorChineseCnt) {
        number = (this.dataForm.regeditedChineseCnt != undefined ? Number(this.dataForm.regeditedChineseCnt) : 0)
          + (this.dataForm.subcontractorChineseCnt != undefined ? Number(this.dataForm.subcontractorChineseCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.totalCount = number;
      return number;
    },
  },
  methods: {
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      const value = row.dataState;
      if (value && column.property) {
        if (value == '1' && column.property == 'dataState') {
          return {color: '#e6a23c'};
        } else if (value == '2' && column.property == 'dataState') {
          return {color: '#409eff'};
        } else if (value == '3' && column.property == 'dataState') {
          return {color: '#f56c6c'};
        } else if (value == '4' && column.property == 'dataState') {
          return {color: '#67c23a'};
        } else {
          return {}; // 默认情况下不设置特定的背景色
        }
      }
    },
    // 查看流程详情
    headProcessDetail() {
      const processInsId = this.dataForm.processInsId;
      detail({processInsId: processInsId}).then((res) => {
        const {process} = res.data.data;
        const {id, taskId, processInstanceId, processId, processDefKey} =
          process;
        let param = Buffer.from(
          JSON.stringify({
            processId: id,
            taskId,
            processInsId: processInstanceId || processId,
            processDefKey,
          })
        ).toString("base64");
        //先直接跳转安环侧流程详情页面，因为平台侧页面跳转会出现导航，安环系统已经去除导航
        let sUrl =
          window.location.origin + "#/process/external/iframe/detail?p=" + param + '&isNewWindow=true';
        window.open(sUrl, "_blank");
      });
    },
    receiveData(row) {
      let templateCode = "domesticCode";
      let type = "summary";
      this.$loading();
      this.orgCode = row.fillDeptId;
      reportTree(templateCode, type, row.fillDeptId, row.statDate)
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.treeData[0].id)
          })
          this.nodeId = row.fillDeptId;
          this.defaultExpandedKeys.push(row.fillDeptId)
          this.fillDeptName = row.fillDeptName;
          this.dataForm.orgName = row.fillDeptName;
          this.query.affiliationDept = this.nodeId;
          this.dataForm.statDate = row.statDate
          this.query.formDate = row.statDate;
          this.summaryGetDataList()
          this.tableOnLoad(this.page);
          this.treeShow = true;
          this.treeLoading = false;
          this.query.unitCode = this.nodeId;
          // this.tableOnLoad(this.page, {});
        })
        .catch((err) => {
          this.$loading().close();
          this.treeLoading = false;
        }).finally(() => {
        this.$loading().close();
      });
    },
    initData() {
      if (this.treeData.length != 0) {
        this.defaultCheckedKeys.push(this.treeData[0].id)
      }
      this.treeShow = true
      // this.onLoad(this.page);
    },
    rowEdit(row) {
      this.isFormDialog = true;
      row.orgCode = this.nodeId
      this.formData = {
        row: JSON.stringify(row),
        type: 'edit',
        orgCode: this.nodeId
      }
    },
    headUrging() {
      if (this.selectionList.length == 0) {
        this.$message.warning("请勾选数据！")
        return;
      }
      const hasDataStateNotEqualToTwo = this.selectionList.some(item => item.dataState != "1" && item.dataState != "3");
      if (hasDataStateNotEqualToTwo) {
        this.$message.warning("请确认勾选的数据状态为待提交或驳回！")
      } else {
        this.$loading()
        const result = this.selectionList.map(item => item.orgCode).join(',');
        let data = {deptId: result, reportCode: "domesticCode"}
        reviewAndUrging(data)
          .then((result) => {
            if (result.data.code == 200) {
              this.$loading().close()
              this.onLoad(this.page);
              this.$message.success("催办成功!")
            }
          }).finally(() => {
          this.$loading().close()
        })
      }
    },
    reject() {
      if (this.selectionList.length !== 0) {
        let idList = [];
        this.selectionList.forEach((e) => {
          if (e.dataState != "4") {
            idList.push(e.id)
          }
        });
        if (idList.length === 0) {
          this.$prompt('请输入驳回原因:', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputType: "textarea",
            inputPattern: /^[\s\S]{1,200}$/, // 使用正则表达式限制长度在1到200个字符之间
            inputErrorMessage: '请输入1到200个字符的驳回原因', // 当输入不符合正则表达式时显示的错误信息
            closeOnClickModal: false
          }).then(({value}) => {
            let templateCode = "domesticCode"
            this.$loading()
            rejectSendingMessage(this.selectionList, value, templateCode).then((res) => {
              if (res.data.code == 200) {
                this.initTree();
                this.tableOnLoad(this.page, {});
                this.$message({
                  type: 'success',
                  message: '驳回成功'
                });
              }
            }).catch((e) => {
              this.$message({
                type: 'warning',
                message: '取消驳回'
              });
            }).finally(() => {
              this.$loading().close()
            })
          }).catch(() => {
            this.$message({
              type: 'warning',
              message: '取消驳回'
            });
          }).finally(() => {
            this.$loading().close()
          });
        } else {
          return this.$message.warning("请确保选择数据中全部是已通过!")
        }
      } else {
        return this.$message.warning("至少选择一条数据!")
      }
    },
    rowView(row) {
      this.isFormDialog = true;
      row.orgCode = this.nodeId
      this.formData = {
        row: JSON.stringify(row),
        type: 'view',
        orgCode: this.nodeId
      }
    },
    domesticDialog() {
      this.isFormDialog = false;
      this.tableOnLoad(this.page, {});
    },
    handleChildMethod() {
      this.$refs.form.formDialog = false;
      this.tableOnLoad(this.page, {});
    },
    findDictValueByState() {
      if (this.dataForm.dataState) {
        const item = this.dicData.find(item => item.dictKey === this.dataForm.dataState);
        return item.dictValue
      } else {
        return null
      }
    },
    selectList(e) {
      this.readonly = true;
      if (this.query.formDate) {
        this.summaryGetDataList();
      } else {
        this.dataForm = []
        this.dataForm.orgName = this.fillDeptName
      }
      this.tableOnLoad(this.page)
    },
    headReportView() {
      if (this.nodeId) {
        if (this.query.formDate) {
          // // 将字符串转换为 Date 对象
          // const dateObject = new Date(this.query.formDate);
          // // 使用 toISOString 获取 ISO 格式的日期字符串
          // // 然后我们截取前10个字符，即 'YYYY-MM' 部分
          // const formattedDate = dateObject.toISOString().substring(0, 7);
          // console.log(formattedDate)
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=domesticCode&stat_month=" + this.query.formDate)
        } else {
          window.open('/#/business/reportManager/reportView/index?isNewWindow=true&deptId=' + this.nodeId + "&reportCode=domesticCode")
        }
      } else {
        this.$message.warning("请先选择组织")
      }
    },
    summaryGetDataList() {
      let data = {
        formDate: this.query.formDate,
        orgCode: this.nodeId,
        projectArea: "1"
      }
      summaryGetData(data).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.dataForm.statDate = data.formDate
          this.dataState = this.findDictValueByState()
          if (!res.data.data.dataState) {
            this.dataForm.dataState = "1"
          }
          if (!this.dataForm.orgName) {
            this.dataForm.orgName = this.fillDeptName
          }
          this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
          this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
          this.totalCountS = res.data.data.totalCountS;
        }
      })
    },
    initTree() {
      if (this.type != 'workflow') {
        let templateCode = "domesticCode";
        let type = "summary";
        this.treeLoading = true;
        reportTree(templateCode, type, this.orgCode, this.dataForm.statDate)
          .then((result) => {
            this.treeData = result.data.data;
            if (this.treeData == "您不需要填报该报表!") {
              this.$message.warning("您不需要填报该报表!")
              return
            }
            this.node = this.treeData[0];
            if (this.type != 'workflow') {
              this.query.affiliationDept = this.nodeId;
            }
            if (this.currentNodeKey == '') {
              this.$nextTick(() => {
                this.defaultCheckedKeys.push(this.treeData[0].id)
              })
            }
            this.treeLoading = false;
            this.query.unitCode = this.nodeId;
            this.query.affiliationDept = this.nodeId;
            // this.tableOnLoad(this.page, {});
          })
          .catch((err) => {
            this.treeLoading = true;
          });
      } else {
        this.tableOnLoad(this.page, {});
      }
      if (!this.dataForm.dataState) {
        this.dataForm.dataState = "1"
      }
    },
    selectionChange(list) {
      this.selectionList = list
    },
    closeDialog() {
      this.selectionList = [];
      this.paperPage.currentPage = 1;
      this.selectTopicsDialog = false;
    },
    selectionClear() {
      this.selectionList = [];
    },
    gridHeadEmpty(query) {
      this.tableOnLoad(this.paperPage, query)
    },
    gridHeadSearch(query) {
      this.paperPage.currentPage = 1;
      this.tableOnLoad(this.paperPage, query)
    },
    treeNodeClick(item) {
      this.whether = item.whether
      this.dataForm = [];
      this.readonly = true
      let params = {
        firm: item.id
      }
      this.nodeId = item.id;
      this.query.unitCode = this.nodeId;
      this.query.affiliationDept = this.nodeId;
      this.node = item;
      this.tableOnLoad(this.page, params)
      this.deptId = item.id;
      if (this.includeStatus) {
        this.getDeptIds(item.id);
      }
      this.fillDeptName = this.node.deptName
      if (this.query.formDate) {
        this.summaryGetDataList();
      }
    },
    headSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.dataForm.id && this.dataForm.id != '-1') {
            let params = {
              projectArea: 1,
              orgCode: this.nodeId,
              statDate: this.dataForm.statDate,
              id: this.dataForm.id
            }
            submitVerify(params).then((res) => {
              if (res.data.code === 200) {
                if (res.data.data) {
                  if (this.dataForm.id && this.dataForm.statDate) {
                    // 提交逻辑
                    this.submit()
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                } else {
                  this.$message.warning("请确保当前组织下级机构的填报数据状态均为审核通过");
                }
              }
            })
          } else {
            this.$message.warning("请保存表单数据后再进行操作");
          }
        } else {
          this.$message.warning("当前报表数据有误，请先汇总数据或修改数据！");
        }
      })
    },
    headSummary() {
      let dataList = []
      this.tableData.forEach(item => {
        if (item.dataState != "4" && item.dataState !== null && item.id != -1) {
          dataList.push(item);
        }
      })
      if (dataList.length !== 0) {
        this.$confirm("有任务未完成不能汇总！", {
          showCancelButton: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning"
        })
      } else {
        if (this.dataForm.id == undefined || this.dataForm.id == -1) {
          if (this.dataForm.statDate) {
            let statDate = this.dataForm.statDate
            let data = {}
            if (this.type === 'workflow') {
              data = {
                projectArea: 1,
                affiliationDept: this.dataForm.orgCode,
                templateCode: "domesticCode",
                formDate: this.dataForm.statDate,
              }
            } else {
              data = {
                projectArea: 1,
                affiliationDept: this.nodeId,
                templateCode: "domesticCode",
                formDate: this.dataForm.statDate,
              }
            }
            this.$loading()
            summaryClick(data).then((res) => {
              if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
                this.dataForm = res.data.data;
                this.dataForm.orgName = this.fillDeptName;
                if (this.type === 'workflow') {
                  this.dataForm.projectCode = this.dataForm.orgCode;
                } else {
                  this.dataForm.projectCode = this.nodeId;
                }
                this.dataForm.projectName = this.fillDeptName;
                this.dataForm.statDate = statDate;
                this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
                this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
                this.totalCountS = res.data.data.totalCountS;
                this.headSave();
                this.summary = true; // 汇总标识
              } else {
                if (res.data.data == "noSubordinate") {
                  this.$confirm("没有下级数据，无需汇总!", {
                    showCancelButton: false,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: "warning"
                  })
                } else {
                  this.$confirm(res.data.data, {
                    showCancelButton: false,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: "warning"
                  })
                }
              }
            }).then(() => {
              return;
            }).finally(() => {
              this.$loading().close();
            })
          } else {
            this.$message.warning("请选择统计时间");
          }
        } else {
          getDomesticDetail(this.dataForm.id).then((res) => {
            if (res.data.code === 200) {
              let dataForm = res.data.data
              if (this.dataForm.statDate) {
                let statDate = this.dataForm.statDate
                let data = {}
                if (this.type === 'workflow') {
                  data = {
                    id: dataForm.id,
                    projectArea: 1,
                    affiliationDept: this.dataForm.orgCode,
                    templateCode: "domesticCode",
                    formDate: this.dataForm.statDate,
                  }
                } else {
                  data = {
                    id: dataForm.id,
                    projectArea: 1,
                    affiliationDept: this.nodeId,
                    templateCode: "domesticCode",
                    formDate: this.dataForm.statDate,
                  }
                }
                this.$loading()
                summaryClick(data).then((res) => {
                  if (res.data.code === 200 && res.data.data != "有任务未完成不能汇总,详情请通过搜索条件去确认未完成任务." && res.data.data != "noSubordinate") {
                    this.dataForm = res.data.data;
                    this.dataForm.id = dataForm.id;
                    if (this.type === 'workflow') {
                      this.dataForm.projectCode = this.dataForm.projectCode;
                      this.dataForm.projectName = this.dataForm.projectName;
                    } else {
                      this.dataForm.projectCode = this.nodeId;
                      this.dataForm.projectName = this.fillDeptName;
                    }
                    this.dataForm.orgName = res.data.data.projectName
                    this.dataForm.statDate = statDate;
                    this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
                    this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
                    this.totalCountS = res.data.data.totalCountS;
                    this.headSave();
                    this.summary = true; // 汇总标识
                  } else {
                    if (res.data.data == "noSubordinate") {
                      this.$confirm("没有下级数据，无需汇总!", {
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: "warning"
                      })
                    } else {
                      this.$confirm(res.data.data, {
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: "warning"
                      })
                    }
                  }
                }).then(() => {
                  return;
                }).finally(() => {
                  this.$loading().close();
                })
              } else {
                this.$message.warning("请选择统计时间");
              }
            }
          })
        }
      }
    },
    headSave() {
      if (this.type !== 'workflow') {
        this.dataForm.projectArea = 1
        this.dataForm.orgCode = this.nodeId
        this.dataForm.statDate = this.query.formDate
        this.dataForm.totalCount = this.totalChineseCnt
        this.dataForm.projectCode = this.nodeId
        this.dataForm.projectName = this.fillDeptName
        if (this.dataForm.id === -1) {
          this.dataForm.id = null;
        }
        if (this.dataForm.statDate) {
          this.$nextTick(() => {
            this.$refs.dataForm.validate((valid) => {
              if (valid) {
                submitSummaryByDomestic(this.dataForm).then((res) => {
                  if (res.data.code === 200) {
                    this.dataForm = res.data.data;
                    getDomesticDetail(this.dataForm.id).then((res) => {
                      if (res.data.code === 200) {
                        this.dataForm = res.data.data
                        this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
                        this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
                        this.totalCountS = res.data.data.totalCountS;
                        this.query.formDate = res.data.data.statDate
                        this.dataForm.orgName = res.data.data.projectName
                      }
                    })
                    this.$message.success("操作成功");
                  }
                })
              }
            })
          })
        } else {
          this.$message.warning("请选择统计时间");
        }
      } else {
        this.$nextTick(() => {
          this.$refs.dataForm.validate((valid) => {
            if (valid) {
              submitSummaryByDomestic(this.dataForm).then((res) => {
                if (res.data.code === 200) {
                  this.dataForm = res.data.data;
                  getDomesticDetail(this.dataForm.id).then((res) => {
                    if (res.data.code === 200) {
                      this.dataForm = res.data.data
                      this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
                      this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
                      this.totalCountS = res.data.data.totalCountS;
                      this.query.formDate = res.data.data.statDate
                      this.dataForm.orgName = res.data.data.projectName
                    }
                  })
                  this.$message.success("操作成功");
                }
              })
            }
          })
        })
      }
    },
    headUpdate() {
      this.readonly = false;
    },
    rowDelete(index) {
      this.tableData.splice(index, 1);
    },
    tableOnLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      if (this.type != 'workflow') {
        params = {
          projectArea: 1,
          affiliationDept: this.nodeId,
          formDate: this.dataForm.statDate,
          templateCode: "domesticCode",
        }
      } else {
        params = {
          projectArea: 1,
          affiliationDept: this.dataForm.orgCode,
          formDate: this.query.formDate,
          templateCode: "domesticCode",
        }
      }
      summaryGetPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      ).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            const data = res.data.data;
            this.page.total = data.total;
            this.tableData = data.records;
            this.selectionClear();
          }
        }
      })
        .finally(() => {
          this.tableLoading = false;
        })
    },
    //提交
    submit() {
      if (this.dataForm.id) {
        if (this.dataForm.statDate) {
          this.dataForm.templateCode = "domesticCode"
          this.dataForm.projectArea = "1"
          this.dataForm.formDate = this.dataForm.statDate
          this.dataForm.affiliationDept = this.dataForm.orgCode
          summaryClickDataState(this.dataForm).then(res => {
            if (res.data.code === 200) {
              if (res.data.data) {
                this.$confirm("下级还有数据未通过，是否继续提交?", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(() => {
                  this.$confirm("确定将提交?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                    .then(() => {
                      this.$loading();
                      this.rpTaskAttributeSubmit(this.dataForm);
                    }).catch(() => {
                  })
                    .finally(() => {
                      this.$loading().close();
                    });
                });
              } else {
                this.$confirm("确定将提交?", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                  .then(() => {
                    this.$loading();
                    this.rpTaskAttributeSubmit(this.dataForm);
                  }).catch(() => {
                })
                  .finally(() => {
                    this.$loading().close();
                  });
              }
            }
          })
        } else {
          this.$message.warning("统计日期为空");
        }
      } else {
        this.$message.warning("请先保存数据");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.dataForm.totalCount = this.totalChineseCnt
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 1
      this.dataForm.dataState = 4;
      this.dataForm.orgCode = this.nodeId
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      submitSummaryByDomestic(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.restRefresh();
          getDomesticDetail(this.dataForm.id).then((res) => {
            if (res.data.code === 200) {
              this.readonly = true;
              this.dataForm = res.data.data
              this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
              this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
              this.totalCountS = res.data.data.totalCountS;
              this.query.formDate = res.data.data.statDate
              this.dataForm.orgName = res.data.data.projectName
            }
          })
          this.initTree();
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
      }).finally(() => {
        this.$loading().close()
      })
      // this.dataForm.updateUserName = this.userInfo.real_name
      // this.dataForm.projectArea = 1
      // this.dataForm.orgCode = this.nodeId
      // this.formProcess.id = data.id;
      // this.formProcess.deptId = this.nodeId;
      // this.formProcess.paperName = this.dataForm.paperName;
      // this.handleStartProcess5(true, true).then((res) => {
      //   let processInsId = res.data.data
      //   data.processInsId = processInsId;
      //   data.dataState = 2;
      //   data.projectArea = 1
      //   data.orgCode = this.nodeId
      //   getProcessDetail({processInsId: processInsId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       data.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     submitSummaryByDomestic(data).then((res) => {
      //       if (res.data.code === 200) {
      //         this.restRefresh();
      //         getDomesticDetail(this.dataForm.id).then((res) => {
      //           if (res.data.code === 200) {
      //             this.readonly = true;
      //             this.dataForm = res.data.data
      //             this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
      //             this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
      //             this.totalCountS = res.data.data.totalCountS;
      //             this.query.formDate = res.data.data.statDate
      //             this.dataForm.orgName = res.data.data.projectName
      //           }
      //         })
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //       }
      //     })
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
  },
  created() {
    dictionaryBiz("data_status_report").then((res) => {
      this.dataStateDic = res.data.data;
    });
    getDataStatus().then((res) => {
      this.dicData = res.data.data;
    })
    let formDate = formatTime(new Date(), "yyyy-MM-dd");
    this.query.formDate = formDate;
  },
  mounted() {
    // this.getProcessId()
    let type = this.$route.query.type;
    this.type = type
    if (type === 'workflow') {
      this.treeShow = false
      this.isTreeVisible = false;
      let id = this.$route.query.id
      getDomesticDetail(id).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
          this.regeditedChineseCntS = res.data.data.regeditedChineseCntS;
          this.subcontractorChineseCntS = res.data.data.subcontractorChineseCntS;
          this.totalCountS = res.data.data.totalCountS;
          this.query.formDate = res.data.data.statDate
          this.dataForm.orgName = res.data.data.projectName
          // this.initTree()
          if (res.data.data.dataState === 3) {
            this.readonly = true;
          }
        }
      })
    } else {
      // this.initTree()
    }
  }
}
</script>

<style lang="scss" scoped>

.head-section {
  margin-bottom: 20px;
}

//::v-deep .domesticProjectSummary .avue-crud .el-table {
//  height: calc(100vh - 423px) !important;
//  max-height: calc(100vh - 423px) !important;
//}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

::v-deep .el-form-item__content {
  display: flex;
}

.searchInput {
  width: 300px;
  position: absolute;
  top: 9px;
  right: 255px;
}

.head-picker ::v-deep .el-date-editor.el-input {
  width: 20% !important;
}

::v-deep .gridHeadLayout .el-button-group > .el-button:last-child {
  display: none !important;
}


.demo-ruleForm ::v-deep el-date-picker {
  width: 100% !important;
}
</style>
